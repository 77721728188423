import {
  addSDataSheetRow,
  BRANDLOGOS,
  createARelevantMessage,
  createEmptyLeadDetail,
  getMaintenanceItem,
  getRemainingTime,
  getStatusById
} from '@/utils'
import { defineStore } from 'pinia'
import type { LeadsFilter, State } from './types'
import { LeadsFiltersType } from './types'
import {
  AssignAgentDto,
  FilterValue,
  type LeadsClient,
  UpdateAppointmentDateDto
} from 'lead-tray-api-ts'
import { useTranslator } from 'sima-suite-translator-vue3'
import {
  EnumSDataSheetItemType,
  SAlertType,
  type SDataSheetItem,
  SIconButtonVariant
} from 'sima-suite-components-vue3'
import dayjs from '@/utils/dayjs'
import { ProcessStatusId } from '@/types'
import { v4 as uuidV4 } from 'uuid'

let leadsClient: LeadsClient
export const initLeadsClient = (client: LeadsClient) => {
  leadsClient = client
}

const { translate, language } = useTranslator()

export const useLeadsStore = defineStore({
  id: 'leads',
  state: (): State => ({
    leadsList: [],
    currentLead: createEmptyLeadDetail(),
    vehicleInformation: undefined,
    totalPages: 1,
    currentPage: 1,
    filters: []
  }),
  actions: {
    async getLeads(filterText: string | null = null) {
      try {
        const otherFilters = filterText ? null : this.getFilters
        const { data, totalPages } = await leadsClient.get(
          filterText,
          otherFilters,
          null,
          this.currentPage,
          100
        )
        this.leadsList = data!.map((lead) => {
          return { ...lead, expirationDate: new Date(lead.expirationDate) }
        })
        this.totalPages = totalPages
      } catch (error) {
        createARelevantMessage(translate('App.Common.ErrorMessage'), SAlertType.ERROR)
      }
    },
    async getLeadById(id: string) {
      try {
        this.currentLead = await leadsClient.getDetails(id)
      } catch (error: any) {
        if (error) {
          throw error
        }
      }
    },
    async assignLead(leadId: string, agentName: string) {
      try {
        const newAgentAssign = new AssignAgentDto({ name: agentName })
        await leadsClient.assignAgent(leadId, newAgentAssign)
      } catch (error) {
        createARelevantMessage(translate('App.Common.ErrorMessage'), SAlertType.ERROR)
      }
    },
    async getCarInfo(leadId: string) {
      try {
        this.vehicleInformation = await leadsClient.getVehicleAdditionalData(leadId)
      } catch (error) {
        createARelevantMessage(translate('App.Common.ErrorMessage'), SAlertType.ERROR)
      }
    },
    removeFilterByType(typeFilter: LeadsFiltersType) {
      this.filters = this.filters!.filter((filter) => {
        return filter.type !== typeFilter
      })
    },
    addFilter(currentFilter: LeadsFilter) {
      this.removeFilterByType(currentFilter.type)
      this.filters.push(currentFilter)
    },
    async cancelAppointment(leadId: string) {
      try {
        await leadsClient.cancelAppointment(leadId)
        this.currentLead.tracking.appointmentDate = undefined
      } catch (error) {
        createARelevantMessage(translate('App.Common.ErrorMessage'), SAlertType.ERROR)
      }
    },
    async modifyAppointment(leadId: string, newDate: Date) {
      try {
        await leadsClient.updateAppointmentDate(
          leadId,
          new UpdateAppointmentDateDto({ newAppointmentDate: newDate })
        )
        this.currentLead.tracking.appointmentDate = newDate
      } catch (error) {
        createARelevantMessage(translate('App.Common.ErrorMessage'), SAlertType.ERROR)
      }
    }
  },
  getters: {
    getFilters(): FilterValue[] {
      return this.filters.map((filter) => filter.filter)
    },
    getLeadResumeItems(): SDataSheetItem[] {
      return [
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Info.ResumeDataSheet.RemainingTime'),
          fieldText:
            this.currentLead.status.id === ProcessStatusId.EXPIRED
              ? '00:00:00'
              : getRemainingTime(this.currentLead.expirationDate),
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Info.ResumeDataSheet.Dealer'),
          fieldText: this.currentLead.dealer.name,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Info.ResumeDataSheet.Customer'),
          fieldText: `${this.currentLead.customer.salutationTitle} ${this.currentLead.customer.firstName} ${this.currentLead.customer.lastName}`,
          type: EnumSDataSheetItemType.TEXT
        },

        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Info.ResumeDataSheet.Brand'),
          src: BRANDLOGOS[this.currentLead.vehicle.brand],
          alt: translate(`App.CarBrand.${this.currentLead.vehicle.brand}`),
          type: EnumSDataSheetItemType.IMAGE
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Info.ResumeDataSheet.Model'),
          fieldText: `${this.currentLead.vehicle.modelName}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Info.ResumeDataSheet.Source'),
          fieldText: `${
            this.currentLead.customerRequest.source === null
              ? ''
              : this.currentLead.customerRequest.source
          }`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Info.ResumeDataSheet.Topic'),
          fieldText: `${
            this.currentLead.customerRequest.topic === null
              ? ''
              : translate(`Lead.Topic.${this.currentLead.customerRequest.topic}`)
          }`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Info.ResumeDataSheet.Status'),
          fieldText: `${getStatusById(this.currentLead.status.id)}`,
          type: EnumSDataSheetItemType.TEXT
        }
      ]
    },
    getLeadDataItems(): SDataSheetItem[] {
      const maintenanceVehiclesItems =
        this.currentLead.leadData.vehicleMaintenances.map(getMaintenanceItem)
      return [
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.Data.Mileage'),
          fieldText: `${this.currentLead.leadData.mileage} km`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.Data.WarningLights'),
          fieldText: `${this.currentLead.leadData.warningLights}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.Data.LeadID'),
          fieldText: `${this.currentLead.leadId}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.Data.GeneralInspection'),
          fieldText: ``,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.Data.OpenInLeadSource'),
          fieldText: ``,
          type: EnumSDataSheetItemType.TEXT
        },
        ...maintenanceVehiclesItems
      ]
    },
    getLeadTrackingItems(): SDataSheetItem[] {
      const trackingDataItems = []
      const { status, tracking } = this.currentLead
      const { id } = status
      const { createdAt, contactAttemps, notes, closureReason, appointmentDate } = tracking

      const addRow = (title: string, text: string) =>
        addSDataSheetRow(trackingDataItems, translate(title), text)
      const formatDate = (date: Date) =>
        dayjs(date.toISOString()).utc().locale(language.value).format('L - LT')

      addRow('Detail.Section.Info.TrackingDataSheet.CreateAt', formatDate(createdAt))

      // TODO: Uncomment when the remove bug between readable and writable models in backend
      // if (id >= ProcessStatusId.ASSIGNED && accepted !== null) {
      //   addRow('Detail.Section.Info.TrackingDataSheet.Accepted', formatDate(accepted!))
      // }

      if (id >= ProcessStatusId.CONTACTED) {
        addRow('Detail.Section.Info.TrackingDataSheet.ContactAttempts', contactAttemps.toString())
      }

      if (id === ProcessStatusId.CANCELED) {
        const closureText =
          closureReason === 'Others'
            ? `${translate('Detail.Modal.Select.' + closureReason)} ${notes}`
            : translate('Detail.Modal.Select.' + closureReason)
        addRow('Detail.Section.Info.TrackingDataSheet.ClosureReason', closureText)
      }

      if (id === ProcessStatusId.SCHEDULED) {
        const appointmentText = appointmentDate
          ? formatDate(appointmentDate)
          : translate('Detail.Section.Info.TrackingDataSheet.AppointmentDateCanceled')
        addRow('Detail.Section.Info.TrackingDataSheet.AppointmentDate', appointmentText)
      }

      return trackingDataItems
    },
    getVehicleDataItems(): SDataSheetItem[] {
      return [
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.Vehicle.VIN'),
          fieldText: `${this.currentLead.vehicle.vin}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.Vehicle.Plate'),
          fieldText: `${this.currentLead.vehicle.licensePlate}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.Vehicle.Brand'),
          fieldText: translate(`App.CarBrand.${this.currentLead.vehicle.brand}`),
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.Vehicle.ModelName'),
          fieldText: `${this.currentLead.vehicle.modelName}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.Vehicle.ModelYear'),
          fieldText: `${this.currentLead.vehicle.modelYear}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.Vehicle.EngineCode'),
          fieldText: `${this.currentLead.vehicle.engineCode}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.Vehicle.TransmissionCode'),
          fieldText: `${this.currentLead.vehicle.transmissionCode}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.Vehicle.Fleet'),
          fieldText: `${this.currentLead.vehicle.fleet}`,
          type: EnumSDataSheetItemType.TEXT
        }
      ]
    },
    getCustomerDataItems(): SDataSheetItem[] {
      return [
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.CustomerData.Name'),
          fieldText: `${this.currentLead.customer.salutationTitle} ${this.currentLead.customer.firstName} ${this.currentLead.customer.lastName}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.CustomerData.Phone'),
          fieldText: `${this.currentLead.customer.telephone}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.CustomerData.Email'),
          fieldText: `${this.currentLead.customer.email}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.CustomerData.Street'),
          fieldText: `${this.currentLead.customer.address.street}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.CustomerData.Number'),
          fieldText: `${this.currentLead.customer.address.houseNumber}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.CustomerData.Affix'),
          fieldText: `${this.currentLead.customer.address.addressAffix}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.CustomerData.PostCode'),
          fieldText: `${this.currentLead.customer.address.postCode}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.CustomerData.City'),
          fieldText: `${this.currentLead.customer.address.city}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.CustomerData.Region'),
          fieldText: `${this.currentLead.customer.address.region}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.CustomerData.Country'),
          fieldText: `${this.currentLead.customer.address.country}`,
          type: EnumSDataSheetItemType.TEXT
        }
      ]
    },
    getCustomerRequestItems(): SDataSheetItem[] {
      const dateTransform = (desiredDate: Date | null | undefined) => {
        if (desiredDate) {
          return dayjs(desiredDate).format('DD-MM-YYYY')
        }
        return ''
      }

      // {
      //   id: uuidv4(),
      //     title: translate('Detail.Section.Summary.CustomerRequest.FurtherInformation'),
      //   variant: SIconButtonVariant.tonal,
      //   icon: 'mdi-book-open-outline',
      //   fieldTitle: translate('Detail.Section.Summary.CustomerRequest.FurtherInformation'),
      //   type: EnumSDataSheetItemType.ICONBUTTON
      // }

      let furtherInformation: SDataSheetItem

      if (!this.currentLead.customerRequest.furtherInformation) {
        furtherInformation = {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.CustomerRequest.FurtherInformation'),
          fieldText: translate('Detail.Section.Summary.CustomerRequest.NoFurtherInformation'),
          type: EnumSDataSheetItemType.TEXT
        }
      } else {
        furtherInformation = {
          id: uuidV4(),
          title: translate('Detail.Section.Summary.CustomerRequest.FurtherInformation'),
          variant: SIconButtonVariant.tonal,
          icon: 'mdi-book-open-outline',
          fieldTitle: translate('Detail.Section.Summary.CustomerRequest.FurtherInformation'),
          type: EnumSDataSheetItemType.ICONBUTTON
        }
      }

      return [
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.CustomerRequest.DesiredDate'),
          fieldText: `${dateTransform(this.currentLead.customerRequest.desiredDate?.dateTime)}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.CustomerRequest.ReplacementMobility'),
          fieldText: `${
            this.currentLead.customerRequest.replacementMobilities.length > 0
              ? this.currentLead.customerRequest.replacementMobilities[0]
              : ''
          }`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.CustomerRequest.ServiceScope'),
          fieldText: `${
            this.currentLead.customerRequest.serviceScopes.length > 0
              ? this.currentLead.customerRequest.serviceScopes[0]
              : ''
          }`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Summary.CustomerRequest.Specifications'),
          fieldText: ``,
          type: EnumSDataSheetItemType.TEXT
        },
        { ...furtherInformation }
      ]
    },
    getLinkedLeadsItems(): { [key: string]: any }[] {
      return this.currentLead.linkedLeads.map((lead) => {
        return {
          ['id']: { value: lead.id, visible: false },
          [translate('Detail.Section.Summary.LinkedLeads.Customer')]: {
            value: `${lead.saludationTitle ? lead.saludationTitle : ''} ${lead.customerFirstName} ${
              lead.customerLastName
            }`,
            visible: true
          },
          [translate('Detail.Section.Summary.LinkedLeads.Source')]: {
            value: lead.source,
            visible: true
          },
          [translate('Detail.Section.Summary.LinkedLeads.Topic')]: {
            value: translate(`Lead.Topic.${lead.topic}`),
            visible: true
          },
          [translate('Detail.Section.Summary.LinkedLeads.Status')]: {
            value: getStatusById(lead.status.id),
            visible: true
          }
        }
      })
    },
    getContactCustomerInformation(): SDataSheetItem[] {
      return [
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Customer.Salutation'),
          fieldText: `${this.currentLead.customer.salutationTitle}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Customer.FirstName'),
          fieldText: `${this.currentLead.customer.firstName}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Customer.LastName'),
          fieldText: `${this.currentLead.customer.lastName}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Customer.Phone'),
          fieldText: `${this.currentLead.customer.telephone}`,
          type: EnumSDataSheetItemType.TEXT
        },
        {
          id: uuidV4(),
          fieldTitle: translate('Detail.Section.Customer.Email'),
          fieldText: `${this.currentLead.customer.email}`,
          type: EnumSDataSheetItemType.TEXT
        }
      ]
    }
  }
})
