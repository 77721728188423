import {
  AddressDto,
  CustomerDto,
  CustomerRequestDto,
  DealerDto2,
  FilterOperator,
  FilterValue,
  GetAgentStatisticsQueryResult,
  type ILeadDetailsDto,
  type IVehicleMaintenanceDto,
  LeadDataDto,
  LeadDetailsDto,
  PerformanceDto,
  StatusDto,
  TopicType,
  TrackingDto,
  VehicleDto,
  VehicleMaintenanceDto,
  WorkshopLocalDateTime
} from 'lead-tray-api-ts'
import { useTranslator } from 'sima-suite-translator-vue3'
import { type LeadsFilter, LeadsFiltersType } from '@/store/Leads/types'
import type { SAlertType, SDataSheetItem } from 'sima-suite-components-vue3'
import { EnumSDataSheetItemType } from 'sima-suite-components-vue3'
import { useAlert } from '@/composables'
import Audi from '@/assets/Audi.svg'
import Skoda from '@/assets/Skoda.svg'
import Volkswagen from '@/assets/Volkswagen.svg'
import { v4 as uuidV4 } from 'uuid'
import type { ReportFilter } from '@/store/Reports/types'

const { translate } = useTranslator()

export const getRemainingTime = (expirationDate: Date) => {
  const now = new Date()
  const leadDate = new Date(expirationDate)
  const diff = leadDate.getTime() - now.getTime()
  const changeToDays = 24 * 60 * 60 * 1000
  const changeToHours = 60 * 60 * 1000
  let days = Math.floor(diff / changeToDays)
  let hours = Math.floor((diff - days * changeToDays) / changeToHours)
  let minutes = Math.round((diff - days * changeToDays - hours * changeToHours) / 60000)
  if (minutes === 60) {
    hours++
    minutes = 0
  }
  if (hours === 24) {
    days++
    hours = 0
  }
  return [days, hours.toString().padStart(2, '0'), minutes.toString().padStart(2, '0')].join(':')
}

export const createEmptyAgentStatistics = () => {
  return new GetAgentStatisticsQueryResult({
    totalAssigned: 0,
    totalContacted: 0,
    totalCreated: 0
  })
}

export const createEmptyPerformanceDto = (): PerformanceDto => {
  return new PerformanceDto({
    scheduledLeads: 0,
    cancelledLeads: 0,
    expiredLeads: 0,
    cancelledLeadsPercentage: 0,
    expiredLeadsPercentage: 0,
    scheduledLeadsPercentage: 0
  })
}

export const createEmptyFilters = (): ReportFilter[] => {
  return [
    {
      id: 1,
      label: 'Marcas',
      items: [],
      value: ''
    },
    {
      id: 2,
      label: 'Periodo',
      items: [],
      value: ''
    }
  ]
}

export const createEmptyLeadDetail = (): ILeadDetailsDto => {
  return new LeadDetailsDto({
    leadId: '',
    expirationDate: new Date('2023-05-30T14:50:06.6190000Z'),
    status: new StatusDto({
      id: NaN,
      name: ''
    }),
    odometer: NaN,
    dealer: new DealerDto2({
      dealerId: '',
      name: ''
    }),
    vehicle: new VehicleDto({
      vehicleId: '',
      brand: '',
      engineCode: '',
      fleet: '',
      licensePlate: '',
      modelName: '',
      modelYear: '',
      transmissionCode: '',
      vin: ''
    }),
    customer: new CustomerDto({
      customerId: '',
      address: new AddressDto({
        street: '',
        houseNumber: '',
        addressAffix: '',
        postCode: '',
        city: '',
        region: '',
        country: ''
      }),
      email: 'user@example.com',
      firstName: '',
      lastName: '',
      salutationTitle: '',
      telephone: ''
    }),
    agent: undefined,
    leadData: new LeadDataDto({
      mileage: NaN,
      warningLights: '',
      vehicleMaintenances: [
        new VehicleMaintenanceDto({
          status: '',
          type: '',
          units: ''
        })
      ]
    }),
    linkedLeads: [],
    customerRequest: new CustomerRequestDto({
      desiredDate: new WorkshopLocalDateTime({
        year: 2023,
        month: 5,
        day: 30,
        timeInSeconds: new Date('2023-05-30').getTime() / 1000,
        dateTime: new Date('2023-05-30')
      }),
      furtherInformation: '',
      replacementMobilities: [],
      serviceScopes: [],
      topic: TopicType.Undefined
    }),
    communicationAttempts: [],
    tracking: new TrackingDto({
      createdAt: new Date('2021-05-30T14:50:06.6190000Z'),
      reminder: new Date('2021-05-30T14:50:06.6190000Z'),
      accepted: new Date('2021-05-30T14:50:06.6190000Z'),
      contactAttemps: 7,
      closureReason: 'ClosureReason',
      notes: ''
    })
  })
}

export const createARelevantMessage = (message: string, type: SAlertType) => {
  const { addAlert } = useAlert()
  addAlert({
    text: message,
    type
  })
}

export const getStatusById = (statusId: number) => {
  const status: Record<string, string> = {
    '0': translate('App.LeadStatus.Created'),
    '1': translate('App.LeadStatus.Assigned'),
    '2': translate('App.LeadStatus.Contacted'),
    '3': translate('App.LeadStatus.Postponed'),
    '4': translate('App.LeadStatus.Scheduled'),
    '5': translate('App.LeadStatus.Expired'),
    '6': translate('App.LeadStatus.Cancelled'),
    '7': translate('App.LeadStatus.Succeeded'),
    default: translate('App.LeadStatus.Unknown')
  }
  return status[statusId.toString()] || status['default']
}

export const getMaintenanceItem = (maintenance: IVehicleMaintenanceDto): SDataSheetItem => {
  const maintenanceDaysText = maintenance.days
    ? `${translate('Translation.Share.In')} ${maintenance.days} ${
        maintenance.days! > 1
          ? translate('Translation.Share.Days')
          : translate('Translation.Share.Day')
      }`
    : ''

  const maintenanceDistanceText = maintenance.distance
    ? `${translate('Translation.Share.In')} ${maintenance.distance} ${maintenance.units}`
    : ''

  let fieldText = ''
  if (maintenanceDistanceText && !maintenanceDaysText) {
    fieldText = maintenanceDistanceText
  }

  if (!maintenanceDistanceText && maintenanceDaysText) {
    fieldText = maintenanceDaysText
  }

  if (maintenanceDistanceText && maintenanceDaysText) {
    fieldText = `${maintenanceDistanceText} · ${maintenanceDaysText}`
  }

  return {
    id: uuidV4(),
    fieldTitle: translate(`Maintenances.type.${maintenance.type}`),
    fieldText,
    type: EnumSDataSheetItemType.TEXT
  }
}

export const createFilter = (
  propertyName: string,
  value: string,
  type: LeadsFiltersType,
  filterOperator = FilterOperator.Eq
): LeadsFilter => {
  return {
    type,
    filter: new FilterValue({
      propertyName: propertyName,
      value: value,
      filterOperator
    })
  }
}

export const addSDataSheetRow = (
  items: SDataSheetItem[],
  fieldTitle: string,
  fieldText: string
) => {
  const id = items.length + 1
  items.push({
    id,
    fieldTitle,
    fieldText,
    type: EnumSDataSheetItemType.TEXT
  })
}

export const BRANDLOGOS = {
  Volkswagen,
  VolkswagenCommercials: Volkswagen,
  Skoda: Skoda,
  Audi: Audi
}
